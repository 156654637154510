<template>
  <a-drawer width="38%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="峰会id" prop="summitId" >-->
<!--        <a-input v-model="form.summitId" placeholder="请输入峰会id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="活动开始时间" prop="startTime" >
        <a-date-picker style="width: 100%" v-model="form.startTime" format="YYYY-MM-DD HH:mm:ss" allow-clear disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="活动结束时间" prop="endTime" >
        <a-date-picker style="width: 100%" v-model="form.endTime" format="YYYY-MM-DD HH:mm:ss" allow-clear disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="价格" prop="price" >
        <a-input v-model="form.price" placeholder="请输入价格" disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="身份证号" prop="idCard" >
        <a-input v-model="form.idCard" placeholder="请输入身份证号" disabled="true"/>
      </a-form-model-item>
<!--      <a-form-model-item label="性别" prop="sex" >-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="学员姓名" prop="name" >
        <a-input v-model="form.name" placeholder="请输入学员姓名" disabled="true"/>
      </a-form-model-item>


<!--      <a-form-model-item label="1本地 0外地" prop="nonlocal" >-->
<!--        <a-input v-model="form.nonlocal" placeholder="请输入1本地 0外地" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="区域id" prop="areaId" >-->
<!--        <a-input v-model="form.areaId" placeholder="请输入区域id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="校区" prop="campusId" >
        <a-select placeholder="校区" v-model="form.campusId" style="width: 100%" allow-clear disabled="true">
          <a-select-option v-for="(d, index) in campusList" :key="index" :value="d.id">{{ d.name }}</a-select-option>
        </a-select>
<!--        <a-input v-model="form.campusId" placeholder="请输入校区id" />-->
      </a-form-model-item>

<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="状态0未支付，1已参加，2已核销，3已退款" prop="status" >-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
<!--        <a-space>-->
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            保存-->
<!--          </a-button>-->
<!--          <a-button type="dashed" @click="cancel">-->
<!--            取消-->
<!--          </a-button>-->
<!--        </a-space>-->
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getApply, addApply, updateApply } from '@/api/driving-school/apply'
import {listCampus} from "@/api/driving-school/campus";
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      campusList: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        summitId: null,

        startTime: null,

        endTime: null,

        price: null,

        idCard: null,

        sex: null,

        name: null,

        createTime: null,

        remark: null,

        nonlocal: null,

        areaId: null,

        campusId: null,

        status: '0'

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        status: [
          { required: true, message: '状态0未支付，1已参加，2已核销，3已退款不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getCampusList()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    getCampusList () {
      this.loading = true
      listCampus(null).then(response => {
        this.campusList = response.data
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        summitId: null,
        startTime: null,
        endTime: null,
        price: null,
        idCard: null,
        sex: null,
        name: null,
        createTime: null,
        remark: null,
        nonlocal: null,
        areaId: null,
        campusId: null,
        status: '0'
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getApply({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateApply(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addApply(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
