import request from '@/utils/request'


// 查询校区列表
export function listCampus(query) {
  return request({
    url: '/driving-school/driving-school-campus/list',
    method: 'get',
    params: query
  })
}

// 查询校区分页
export function pageCampus(query) {
  return request({
    url: '/driving-school/driving-school-campus/page',
    method: 'get',
    params: query
  })
}

// 查询校区详细
export function getCampus(data) {
  return request({
    url: '/driving-school/driving-school-campus/detail',
    method: 'get',
    params: data
  })
}
export function dataCity() {
  return request({
    url: '/driving-school/driving-school-campus/listArea',
    method: 'get',
    params: null
  })
}

export function listSchoolDept() {
  return request({
    url: '/driving-school/driving-school-campus/listSchoolDept',
    method: 'get',
    params: null
  })
}
// 新增校区
export function addCampus(data) {
  return request({
    url: '/driving-school/driving-school-campus/add',
    method: 'post',
    data: data
  })
}

// 修改校区
export function updateCampus(data) {
  return request({
    url: '/driving-school/driving-school-campus/edit',
    method: 'post',
    data: data
  })
}

// 删除校区
export function delCampus(data) {
  return request({
    url: '/driving-school/driving-school-campus/delete',
    method: 'post',
    data: data
  })


}
