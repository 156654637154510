import request from '@/utils/request'


// 查询峰会报名列表
export function listApply(query) {
  return request({
    url: '/driving-school/summit-apply/list',
    method: 'get',
    params: query
  })
}

// 查询峰会报名分页
export function pageApply(query) {
  return request({
    url: '/driving-school/summit-apply/page',
    method: 'get',
    params: query
  })
}

// 查询峰会报名详细
export function getApply(data) {
  return request({
    url: '/driving-school/summit-apply/detail',
    method: 'get',
    params: data
  })
}

export function verificationApply(data) {
  return request({
    url: '/driving-school/summit-apply/verification',
    method: 'get',
    params: data
  })
}
export function refundApply(data) {
  return request({
    url: '/driving-school/summit-apply/refund',
    method: 'get',
    params: data
  })
}

// 新增峰会报名
export function addApply(data) {
  return request({
    url: '/driving-school/summit-apply/add',
    method: 'post',
    data: data
  })
}

// 修改峰会报名
export function updateApply(data) {
  return request({
    url: '/driving-school/summit-apply/edit',
    method: 'post',
    data: data
  })
}

// 删除峰会报名
export function delApply(data) {
  return request({
    url: '/driving-school/summit-apply/delete',
    method: 'post',
    data: data
  })
}
